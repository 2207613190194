import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const ContactSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        Contact
      </Typography>
      <Typography align="center">
        お問い合わせは<Link to="/contact-form">こちら</Link>
        からお願いいたします。
      </Typography>
    </Box>
  );
};

export default ContactSection;
