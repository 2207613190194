import React from 'react';
//import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import MonitorLogoImage from '../assets/monitor_logo.png';
import MonitorImage from '../assets/monitor.jpg';
import MonitorWebImage from '../assets/monitor_web.jpg';
import AgricultureImage from '../assets/agriculture.jpg';
//import SpaceImage from '../assets/space.jpg';
import CycleImage from '../assets/cycle.jpg';
import AttachmentImage from '../assets/attachment.jpg';
import DaysImage from '../assets/days.jpg';
import Feature1Image from '../assets/feature1.jpg';
import Feature2Image from '../assets/feature2.jpg';
import theme from '../theme/theme';

const MonitorSection: React.FC = () => {
  const plans = [
    {
      name: 'トライアル',
      price: '¥30,000（税込価格¥33,000）/月',
      features: [
        'カメラ5台/セット',
        '解像度3種から選択可能',
        '3分に1度~60分に1度の撮影を選択可能',
        'Webサービス利用',
        '画像永久保存',
        '初回利用時3ヶ月間限定',
      ],
    },
    {
      name: 'スタンダード',
      price: '¥44,900（税込価格¥49,390）/月',
      features: [
        'カメラ5台/セット',
        '解像度3種から選択可能',
        '3分に1度~60分に1度の撮影を選択可能',
        'Webサービス利用',
        '画像永久保存',
        '故障品の無償交換',
      ],
    },
    {
      name: 'エンタープライズ',
      price: '別途お問い合わせ',
      features: [
        'カメラ5台/セット',
        '解像度3種から選択可能',
        '1分に1度~60分に1度の撮影を選択可能',
        'Webサービス利用',
        '画像永久保存',
        '故障品の無償交換',
        '生成AIによる画像説明機能',
        'タイムラプス動画作成機能',
        '追加のカスタマイズ開発',
      ],
    },
  ];

  const plansAgri = [
    {
      name: '1時間に1度',
      price: '¥2,500（税込価格¥2,750）/月',
      features: [
        'カメラ5台/セット',
        '解像度640×480',
        '1時間に1度の撮影',
        'Webサービス利用',
        '過去1年分の画像閲覧',
      ],
    },
    {
      name: '30分に1度',
      price: '¥3,300（税込価格¥3,630）/月',
      features: [
        'カメラ5台/セット',
        '解像度640×480',
        '30分に1度の撮影',
        'Webサービス利用',
        '過去1年分の画像閲覧',
      ],
    },
    {
      name: '15分に1度',
      price: '¥4,500（税込価格¥4,950）/月',
      features: [
        'カメラ5台/セット',
        '解像度640×480',
        '15分に1度の撮影',
        'Webサービス利用',
        '過去1年分の画像閲覧',
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography>
          <CardMedia
            image={MonitorLogoImage}
            title="MonitorLogo"
            style={{
              width: 242,
              height: 50,
              marginTop: '6rem',
              marginBottom: '2rem',
            }}
          />
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Carousel>
              <CardMedia
                image={MonitorWebImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
              <CardMedia
                image={MonitorImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
              <CardMedia
                image={CycleImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              特徴
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              超小型SIMカメラと画像閲覧用Webサービスの一体型サービス
            </Typography>

            <Typography paragraph>
              「monitor」は、撮影用の超小型SIMカメラと画像閲覧用のWebサービスを一体化した製品です。
            </Typography>
            <Typography paragraph>
              1セットには5台のカメラが含まれ、これらのカメラで定期的な撮影や任意のタイミングでの撮影が可能です。
            </Typography>
            <Typography paragraph>
              カメラにはそれぞれ名前を付けることができ、PCやスマートフォンから、各拠点で撮影された日時と共に画像を閲覧できます。
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              設定不要、電源を入れるだけで使用可能
            </Typography>
            <Typography paragraph>
              「monitor」は、インターネット接続の工事が不要です。
            </Typography>
            <Typography paragraph>
              また、モバイルSIMと設定済みのルーターにより、複雑なネットワーク設定は必要ありません。
            </Typography>
            <Typography paragraph>
              届いたその日に設置し、すぐにモニタリングを開始できます。
            </Typography>
            <CardMedia
              image={Feature2Image}
              title="Feature"
              style={{ height: 450 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              どこでも設置可能で移動も簡単
            </Typography>
            <Typography paragraph>
              「monitor」は、インターネット接続の工事が不要です。
            </Typography>
            <Typography paragraph>
              屋外の回線が引きづらい場所でも利用でき、場所を変えたい時も移動が容易にできます。
            </Typography>
            <CardMedia
              image={Feature1Image}
              title="Feature"
              style={{ height: 400 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              驚くほどの軽さ、小ささ、設置の容易さ
            </Typography>
            <Typography paragraph>
              「monitor」のカメラ1台は約2cm四方で、重さはわずか15gです。
            </Typography>
            <Typography paragraph>
              その超軽量設計により、景観を損ねることなくどこにでも簡単に取り付けられます。
            </Typography>
            <Typography paragraph>
              ジムのフィットネスマシンや飲食店のテーブル席など、さまざまな場所での個別モニタリングにも最適です。
            </Typography>
            <CardMedia
              image={AttachmentImage}
              title="Attachment"
              style={{ height: 390 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              日時検索機能による迅速な画像取得
            </Typography>
            <Typography paragraph>
              これまでになかったカメラ画像の日時指定検索機能。
            </Typography>
            <Typography paragraph>
              「monitor」なら、特定の日時の画像を迅速に絞り込んで取得することができます。
            </Typography>
            <CardMedia image={DaysImage} title="Days" style={{ height: 500 }} />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              プラン
            </Typography>
            <Grid>
              <Grid container spacing={3}>
                {plans.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h3">
                          {plan.name}
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {plan.price}
                        </Typography>
                        <List>
                          {plan.features.map((feature, idx) => (
                            <ListItem key={idx}>
                              <ListItemText primary={feature} />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              驚きの低価格
            </Typography>
            <Typography paragraph>
              monitorのカメラ1台あたりの価格は、業界最安価格帯の¥6,000/月〜を誇ります。
            </Typography>
            <Typography paragraph>
              1セット（カメラ5台）¥30,000/月のトライアルプランからお試し頂けます。
            </Typography>
            <Typography paragraph>
              ※ 3ヶ月のトライアルプラン終了後は、通常プランへ移行となります。
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              農家様＆個人事業主様限定プラン
            </Typography>
            <Grid>
              <Grid container spacing={3}>
                {plansAgri.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h3">
                          {plan.name}
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {plan.price}
                        </Typography>
                        <List>
                          {plan.features.map((feature, idx) => (
                            <ListItem key={idx}>
                              <ListItemText primary={feature} />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              農家様＆個人事業主様限定！さらに驚きの低価格
            </Typography>
            <Typography paragraph>
              農家様と個人事業主様に限り、monitorのカメラ1台あたり¥500/月、1セット¥2,500/月〜の脅威の低価格での提供が可能です。
            </Typography>
            <Typography paragraph>
              採算性により遠隔監視カメラ導入を断念していたケースでも、お気軽に導入頂ける価格帯となっていますのでぜひご検討ください。
            </Typography>
            <Typography paragraph>
              ※ 別途デバイス初期費用がかかります。
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              活用事例
            </Typography>
            <Typography paragraph>
              monitorは様々なシーンでの利用が可能です。各事例を通して、実際の利用方法や成果をご紹介します。
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  {/*<CardActionArea component={Link} to="/use-case/agriculture">*/}
                  <CardActionArea>
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        Coming soon
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              {/*
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/agriculture">
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        農業での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/space">
                    <CardMedia
                      image={SpaceImage}
                      title="Space"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        貸し会議室での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/agriculture">
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        工事現場での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/space">
                    <CardMedia
                      image={SpaceImage}
                      title="Space"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        イベント会場での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/agriculture">
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        コインパーキングでの活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/space">
                    <CardMedia
                      image={SpaceImage}
                      title="Space"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        通学路での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/agriculture">
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        高速道路での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/space">
                    <CardMedia
                      image={SpaceImage}
                      title="Space"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        工程管理での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardActionArea component={Link} to="/use-case/space">
                    <CardMedia
                      image={SpaceImage}
                      title="Space"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        Youtube動画配信での活用事例
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              */}
            </Grid>
          </Grid>

          {/*
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              ドキュメント
            </Typography>
            <Grid>操作方法</Grid>
            <Grid>スペック</Grid>
          </Grid>
          */}
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default MonitorSection;
