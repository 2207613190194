import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ConfirmPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const state = location.state as {
    name: string;
    email: string;
    title: string;
    message: string;
  };

  const handleSubmit = async () => {
    const payload = state;
    const API_ENDPOINT =
      process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3030';

    setLoading(true);

    try {
      const response = await fetch(`${API_ENDPOINT}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert('お問い合わせが送信されました。');
        navigate('/');
      } else {
        alert('エラーが発生しました。再度お試しください。');
      }
    } catch (error) {
      console.error('There was an error sending the request', error);
    }

    setLoading(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Container
        maxWidth="sm"
        sx={{ flexGrow: 1, paddingBottom: '4rem', marginTop: '64px' }}
      >
        <Box
          mt={4}
          mb={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h4" gutterBottom>
            お問い合わせ内容の確認
          </Typography>
          <Typography mb={2}>
            下記の送信内容にお間違いのないことを確認の上、「送信」ボタンを押してください。
          </Typography>
          <Box mt={2} width="100%">
            <Typography>
              <strong>お名前：</strong>
              {state.name}
            </Typography>
          </Box>
          <Box mt={2} width="100%">
            <Typography>
              <strong>メールアドレス：</strong>
              {state.email}
            </Typography>
          </Box>
          <Box mt={2} width="100%">
            <Typography>
              <strong>タイトル：</strong>
              {state.title}
            </Typography>
          </Box>
          <Box mt={2} width="100%">
            <Typography>
              <strong>お問い合わせ内容：</strong>
              {state.message}
            </Typography>
          </Box>
          <Box textAlign="center" mt={4} width="100%">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate('/contact-form', { state });
              }}
            >
              戻る
            </Button>
            {loading ? <CircularProgress /> : null}
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: '20px' }}
              onClick={handleSubmit}
            >
              送信
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ConfirmPage;
