import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import HimawariImage from '../assets/building.jpg';
import { GoldenRatioImage, accessMapStyle } from '../styles/commonStyles';

const AboutUsSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        About us
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>社名</TableCell>
            <TableCell>
              <List>
                <ListItem>合同会社Genpls</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>所在地</TableCell>
            <TableCell>
              <List>
                <ListItem>〒192-0085 東京都八王子市中町９−１０</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>設立</TableCell>
            <TableCell>
              <List>
                <ListItem>2016年2月</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>事業内容</TableCell>
            <TableCell>
              <List>
                <ListItem>レンタルスペース運営</ListItem>
                <ListItem>農業システム開発支援</ListItem>
                <ListItem>ソフトウェア開発支援</ListItem>
                <ListItem>ITシステム運用支援</ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={HimawariImage} alt="Himawari building" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={accessMapStyle}>
            <Box
              component="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7396678296273!2d139.333040364336!3d35.6587851301996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191f4831bd479d%3A0xa60beae49ef36e2d!2sGenpls!5e0!3m2!1sja!2sjp!4v1551849527995"
              width="100%"
              height="100%"
              style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
              allowFullScreen
              title="Genpls Access Map"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsSection;
