import React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MonitorImage from '../assets/monitor.jpg';
import MonitorWebImage from '../assets/monitor_web.jpg';
import AgricultureImage from '../assets/agriculture.jpg';
import SpaceImage from '../assets/space.jpg';
import { GoldenRatioImage } from '../styles/commonStyles';

const ServiceSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        Service
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">
            monitor
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="p">
            『monitor』で、生活はもっと身近なものに。手のひらに収まる超小型SIMカメラが、日々をつなぎます。
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="どこでも簡単設置"
                secondary="インターネット回線やルーターを必要としないため、屋内外を問わず電源があればすぐに利用開始できます。"
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="軽量"
                secondary="たった15gの軽さで、どんな場所にもワンタッチで設置可能。周囲の景観を損ねることはありません。"
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="即時アクセス"
                secondary="専用のWebページを通じて、スマホやPCからいつでも直接モニタリング。必要な時に必要な場所を瞬時にチェック。"
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="新しい日常の一部に"
                secondary="monitorを活用して、いつでもどこでも、あなたの大切な空間を守るサポートをします。新しい体験を、今すぐ始めましょう。"
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
          </List>
          <Typography variant="body1" component="p">
            monitorの事業への利活用を検討中のパートナー事業者様を募集中です。
            ぜひ、お気軽に<Link to="/contact-form">お問い合わせ</Link>ください。
          </Typography>
          <Typography variant="body1" component="p">
            <Link to="/monitor">monitor紹介ページはこちら</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <img
              src={MonitorImage}
              alt="Monitor"
              style={{ width: '100%', marginBottom: '1rem' }}
            />
            <img
              src={MonitorWebImage}
              alt="Monitor Web"
              style={{ width: '100%' }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Typography paragraph></Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={SpaceImage} alt="Space" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            デジタルツイン空間
          </Typography>
          <Typography paragraph>
            デジタルツイン空間は、物理的な空間とデジタル空間をシームレスに連携させる新しいサービスです。
          </Typography>
          <Typography paragraph>
            実際のスペースの状況をリアルタイムでVRやWeb上に反映し、遠隔地からのアクセスやイベントのプレビューが可能になります。
          </Typography>
          <Typography paragraph>
            また、ダッシュボードを通じて、空間利用の最適化や効率的な運営サポートを実現します。
          </Typography>
          <Typography paragraph>
            空間を超えたコミュニケーションの新時代をデジタルツイン空間で体験してください。
          </Typography>
          <Typography paragraph>
            インボイス対応の
            <MuiLink
              href="https://www.spacemarket.com/spaces/errdz-c1uoq_o9j-/?promotion_link=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              レンタルスペースWakU2（ワクワク）のご利用予約はこちら
            </MuiLink>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            デジタルツイン農業
          </Typography>
          <Typography paragraph>
            デジタルツイン農業は、最先端のテクノロジーを活用して、農業を変革する新しいアプローチです。
          </Typography>
          <Typography paragraph>
            生産データをリアルタイムで収集し、AIが最適な栽培を自動で提案します。
          </Typography>
          <Typography paragraph>
            さらに、VRやWebを介してそのデータを可視化し、現場での作業を遠隔操作することも可能です。
          </Typography>
          <Typography paragraph>
            持続可能で効率的な農業の未来にコミットします。
          </Typography>
          <Typography paragraph>
            インボイス対応の
            <MuiLink
              href="https://mercari-shops.com/shops/7dVGY2PNs4MENSqRAiJBFC"
              target="_blank"
              rel="noopener noreferrer"
            >
              北海道札幌市で農業を営むag2（アグアグ）のオンラインショップはこちら
            </MuiLink>
          </Typography>
          <Typography variant="body1" component="p">
            デジタルツイン農業の導入を検討中のパートナー事業者様を募集中です。
            ぜひ、お気軽に<Link to="/contact-form">お問い合わせ</Link>ください。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={AgricultureImage} alt="Agriculture" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
