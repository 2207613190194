import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const establishedYear = 2016;

  return (
    <Box sx={{ bgcolor: 'primary.main', padding: '1rem 0' }}>
      <Typography color="textSecondary" align="center">
        &copy;{' '}
        {establishedYear === currentYear
          ? establishedYear
          : `${establishedYear} - ${currentYear}`}{' '}
        Genpls, LLC.
      </Typography>
    </Box>
  );
};

export default Footer;
